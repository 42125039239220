<template>
  <the-wrapper-wallet>
    <template #leftColItem1>
      <module-contract-deploy />
    </template>
    <template #rightColItem1>
      <module-swap-rates />
    </template>
  </the-wrapper-wallet>
</template>

<script>
export default {
  name: 'TheDeployContractLayout',
  components: {
    TheWrapperWallet: () => import('@/core/components/TheWrapperWallet'),
    ModuleSwapRates: () => import('@/modules/swap/ModuleSwapRates'),
    ModuleContractDeploy: () =>
      import('@/modules/contract/ModuleContractDeploy')
  }
};
</script>
